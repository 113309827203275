import styled from "styled-components"
import { Section } from "./section"
import breakpoints from "./breakpoints"
import { bold18, sectionHeaderFontIncrease, baseGridStyles } from "./base"
import { StyledTabsList, StyledTab, StyledTabSelected } from "../styledComponents/tabs"
import { regular16, regular18 } from "./typography";
export const TestimonialsSection = styled(Section)`
  display: block;
  max-width: 100%;
  background-color: var(--light-blue-lightest);
  padding-bottom: 1.6rem;
  margin-bottom: 2.4rem;
  grid-column: ${props => props.mainTestionialsPage ? '1 / 12' : '1 / 6'};

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 0 0 2.4rem;
    grid-column: ${props => props.mainTestionialsPage ? '1 / 8' : '1 / 5'};
  }
  
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: ${props => props.mainTestionialsPage ? '3 / 13' : '1 / 10'};
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 0 0 3.2rem;
    margin: 0rem;
    grid-column: ${props => props.mainTestionialsPage ? '3 / 11' : '1 / 10'};
  }

  h2 {
    ${sectionHeaderFontIncrease}
    text-align: center;
  }

  p {
    padding: 0;

    a {
      margin-left: 1.6rem;
      margin-right: 1.6rem;
      margin-top: 2.4rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        width: 40rem;
      }
    }

    a.nomargin {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  .testimonial-tabs .sticky{
    padding-top: 0.8rem;
    background: var(--white);
    padding-bottom: 0.8rem;
  }

  .videos  {
    ${baseGridStyles}
    padding: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 0;
    }
    
    ul.testimonials {
      ${baseGridStyles}
      padding: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
      }
      
      li {
        grid-column: span 6;

        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.l}px) {
          grid-column: span 6;
        }

        blockquote {
          width: auto;
          
          @media screen and (min-width: ${breakpoints.l}px) {
            border-bottom: 0;
            margin: 0;
            padding: 0;
          }

        }
      }
    }

    .testimonial-tabs {
      grid-column: span 6;  
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
      }

    }
    blockquote {
      grid-column: span 6;
    }
    p {
      margin-top: 1.6rem;
      grid-column: span 4;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
      }
    }

    figure {
      grid-column: span 4;

      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: span 4;
      }

      &.is-type-video {
        margin-top: 0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
          grid-column: span 3;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: span 4;
        }
      }
    }
  }

  .react-tabs__tab-list {
    ${StyledTabsList}
    position: relative;
    padding-top: 0;
    background: var(--white);
    @media screen and (min-width: ${breakpoints.md}px) {
      justify-content: flex-start;
    }
  }

  .react-tabs__tab {
    ${StyledTab}
    margin-left:-0.8rem;
    font-weight: 700;
  }

  .react-tabs__tab--selected {
    ${StyledTabSelected}
  }

  .react-tabs__tab-panel {
    
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
      }
    }
    ol {
      margin: 1.6rem 0 0 1.6rem;
      padding: 0;

      li {
        list-style-type: decimal;
      }
    }

    blockquote {
      border-left: 0;
      margin: 0 0 2.4rem;
      padding: 0 0 2.4rem;
      border-bottom: 1px solid var(--grey-light);

      @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0 0 3.2rem 0rem;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        margin: 0 auto;
        width: 100%;
        padding: 0 4.6rem 4.6rem 0;
      }
      p {
        ${regular16}
        margin-top: 1.6rem;

        @media screen and (min-width: ${breakpoints.md}px) {
          ${regular18}
        }
        @media screen and (min-width: ${breakpoints.l}px) {
        }

        img.emoji {
          width: 2rem;
          height: 2rem;
          position: relative;
          top: 4.9px;
        }
      }

      

      .react-player {
        margin-top: 1.6rem;
        height: 320px;
        video {
          height: auto!important;
        }
      }
    }

    blockquote p {
      ${bold18}
    }

    
  }
`

export const TestimonialsAside = styled.aside`
  grid-column: 1 / 6;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 5 / 8;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 10 / 13;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 0 0 3.2rem;
  }
`


export const TestimonialsSectionHeader = styled.header`
  ${baseGridStyles}
`


export const VideoTestimonials = styled.div`
  ul {
    ${baseGridStyles}
    list-style-type: none;
    li {
      overflow:hidden;
      padding-bottom:56.25%;
      position:relative;
      height:0;
      grid-column: span 4;
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 2;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: span 4;
      }

      &:nth-child(1){

      }
      iframe {
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
      }
    }
  }
`
export const VideoTestimonialListWrapper = styled.div`

`
export const VideoTestimonialList = styled.ul` // testimonials page 
  ${baseGridStyles}
  margin-top: 1.6rem;
  list-style-type: none;
  li {
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 2;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 4;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: span 6;
    }

    &:nth-child(1){

    }
    iframe {
      left:0;
      top:0;
      height:100%;
      width:100%;
      position:absolute;
    }
  }
`